body{
    padding: 0; margin: 0;
}

.App-Container{
    position: relative;
    height: auto;
    width: 100%;
    overflow: auto;
    z-index: 0;
}

header.App-Header{
    position: relative;
    top: 0; left: 0;
    width: 100%;
    height: 100vh;
}

Header.App-Header video.App-Header-Video{
    position: absolute;
    top: 0; left: 0;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -10;
}

Header.App-Header img.App-Logo{
    position: absolute;
    top: 30px;
    z-index: 100;
    left: 30px;
    width: 130px;
    cursor: pointer;
}

header.App-Header .App-Mobile-Nav-Toggle{
    position: absolute;
    top: 35px;
    right: 30px;
    font-size: 50px;
    color: white;
    z-index: 100;
}

header.App-Header .App-Header-Overlay{
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgb(0,0,0, 0.7);
    z-index: 0;
}

header.App-Header .App-Header-Info{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    margin: auto;
    width: 100%;
    height: 100px;
    text-align: center;
    color: white;
    z-index: 90;
}

header.App-Header .App-Header-Info h1{
    font-size: 35px;
}

nav.Mobile-Navigation{
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 0;
    width: 100%;
    transition: height 5s;
    transition: background-color .1s;
    z-index: 95;
    padding-top: 100px;
}

nav.Mobile-Navigation.Display-Navigation{
    height: 100vh;
    background-color: green;
}

nav.Mobile-Navigationul{
    padding: 0; margin: 0;
}

nav.Mobile-Navigation ul li{
    padding: 0; margin: 0; list-style: none;
    text-align: center;
    padding-top: 50px;
}

nav.Mobile-Navigation ul li a{
    color: white; font-size: 18px;
}

.App-Section{
    padding-top: 50px;
    width: 100%;
    height: auto;
}

.App-Section.App-Section-info{
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
}


.App-Section.App-Section-Image{
    position: relative;
    height: auto;
    min-height: 500px;
    text-align: center;
    overflow: hidden;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    padding-top: 50px;
    padding-bottom: 50px;
}

.App-Section.App-Section-Image .App-Section-Overlay{
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0, 0.7);
    z-index: 0;

}

.App-Section.App-Section-Image .App-Section-Header{
    position: relative;
    z-index: 100;
    border-bottom: 0;
    color: white;
    font-size: 30px;
    text-align: center;
}

.App-Section.App-Section-Image .App-Section-Content{
    position: relative;
    z-index: 100;
    text-align: center;
}

.App-Section.App-Section-Image .App-Section-Content p{
    color: white;
    font-size: 20px;
}

.App-Section.App-Section-Image .App-Section-Content p:first-child{
    margin-top: 0;
}

.App-Section.App-Section-Image .App-Section-Content p{
    margin-top: 50px;
}

.App-Section.No-Header{
    padding-top: 0px;
}

.App-Section .App-Section-Header h2{
    color: green;
    font-size: 30px;
}


.App-Section .App-Section-Content{
    padding-top: 50px;
}

.carousel-item img{
    width: 100%;
}

main.App-Main{
    min-height: 100vh;
}

main.App-Main h1.Page-Name{
    padding: 20px;
    color: darkgreen;
}

main.App-Main .row{
    margin-top: 30px;
}

main.App-Main .row h3{
    color: brown;
}

nav.Desktop-Navigation{
    display: none;
    position: absolute;
    z-index: 100;
    right: 50px;
    top: 50px;
    width: auto;
}

nav.Desktop-Navigation-Nav-Left{
    display: none;
    position: absolute;
    z-index: 100;
    left: 50px;
    top: 40px;
    width: auto;
}

nav.Desktop-Navigation-Nav-Left ul{
    margin: 0;
    padding:0;
}

nav.Desktop-Navigation-Nav-Left ul li{
    margin: 0;
    padding:0;
    list-style: none;
    display: inline-block;
    padding: 10px;
    color: white;
    position: relative;
}

nav.Desktop-Navigation-Nav-Left ul li:hover > ul{
    display: block;
}


nav.Desktop-Navigation-Nav-Left ul li > ul{
    position: absolute;
    text-align: left;
    width: 200px;
    background-color: white;
    color: black;
    padding: 10px;
    display: none;
}

nav.Desktop-Navigation-Nav-Left ul li > ul li a{
    color: black;
}

nav.Desktop-Navigation-Nav-Left ul li ul li{
    display: block; padding: 10px;
}

nav.Desktop-Navigation-Nav-Left ul li:hover{
    cursor: pointer;
    color: green;
}

nav.Desktop-Navigation-Nav-Left ul li a{
    cursor: pointer;
    color: white;
    font-size: 10px;
    text-decoration: none;
}

nav.Desktop-Navigation-Nav-Left ul li a:hover{
    cursor: pointer;
    color: green;
}

nav.Desktop-Navigation ul{
    margin: 0;
    padding:0;
}

nav.Desktop-Navigation ul li{
    margin: 0;
    padding:0;
    list-style: none;
    display: inline-block;
    padding-left: 20px;
    color: white;
}

nav.Desktop-Navigation ul li:hover{
    cursor: pointer;
    color: green;
}

nav.Desktop-Navigation ul li a{
    cursor: pointer;
    color: white;
    font-size: 18px;
    text-decoration: none;
}

nav.Desktop-Navigation ul li a:hover{
    cursor: pointer;
    color: green;
}




.col img{
    width: 100%;
}

.card{ margin: 10px auto;}

footer{
    position: relative;
    background-color: green;
    width: 100%;
    height: auto;
    color: white;
    overflow: hidden;
    padding-top: 50px;
}

footer h3{
    padding-bottom: 20px;
    border-bottom: 3px solid white;
}

footer .row{
    padding: 20px;
}

footer a{
    color: white;
    text-decoration: none;
}

footer .row:nth-child(2){
    background-color: black;
}

footer .row:nth-child(2) ul{
   padding: 0; margin: 0;
}


footer .row:nth-child(2) ul li{
    padding: 0; margin: 0;
 }

footer .col-md-3{
    margin: 0 auto;
    text-align: left;
}