@media only screen and (min-width: 1300px) {
    .App-Section .App-Section-Header h2{
        font-size: 30px;
    }

    .carousel img{
        height: 1000px;
        object-fit: cover;
    }

    .App-Section.App-Section-Image .App-Section-Content{
       width: 60%;
       margin: auto;

    }

    .App-Section .App-Section-Header{
        margin: 0;
        padding-bottom: 0;
        border-bottom: none;
        text-align: left;
    }

    .App-Section .App-Section-Content{
        padding-top: 40px;
    }

    .App-Section .App-Section-Content p{
        font-size: 18px;
    }

    header.App-Header .App-Header-Info{
       width: 45%;
       height: 300px;
    }
    
    header.App-Header .App-Header-Info h1{
        font-size: 80px;
    }

    .App-Section.App-Section-info{
        margin: auto;
        text-align: left;
    }

    nav.Desktop-Navigation{
        display: block;
    }
    
    nav.Desktop-Navigation-Nav-Left{
        display: block;
    }

    .App-Mobile-Nav-Toggle{
        display: none;
    }

    Header.App-Header img.App-Logo{
        position: relative;
        margin: auto;
        left: 0;
        top: 20px;
        width: 200px;
    }

    Header.App-Header{
        text-align: center;
    }

    .card{ margin: 0px 10px 10px 0;}

    footer .row:nth-child(2) ul li{
        display: inline-block;
     }

     footer .row:nth-child(2) ul li:first-child::after{
        content: "|";
        padding-right: 10px;
        padding-left: 10px;
     }
}

@media only screen and (min-width: 1500px) {
    nav.Desktop-Navigation-Nav-Left ul li a{
        font-size: 13px;
    }
}

@media only screen and (min-width: 1700px) {
    nav.Desktop-Navigation-Nav-Left ul li a{
        font-size: 15px;
    }
}

@media only screen and (min-width: 1880px) {
    nav.Desktop-Navigation-Nav-Left ul li a{
        font-size: 18px;
    }
}